<template>
  <b-overlay :show="isLoading" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-title>
        <div class="p-2 d-flex justify-content-between">
          <span>ระบบแจ้งเตือน</span>
          <CreateModal @submit="upsert" />
        </div>
      </b-card-title>
      <b-table small striped hover responsive show-empty class="position-relative items-center" :fields="fields"
        :items="notifications">
        <template #empty="scope">
          <div class="p-2 text-center">
            <img src="/box.png" alt="empty" height="40px"> {{ scope.emptyFiltered ? 'No matching records found' :
              'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(priority)="row">
          {{ row.item.priority === 1 ? 'ปกติ'  : 'สูง' }}
        </template>
        <template #cell(actions)="row">
          <EditModal :data="row.item" @submit="upsert" />
        </template>
        <!-- <template #cell(status)="row">
          <b-badge v-if="row.item.status == 0" pill>รอ</b-badge>
          <b-badge v-if="row.item.status == 1" pill variant="info">ดำเนินการ</b-badge>
          <b-badge v-if="row.item.status == 2" pill variant="success">สำเร็จ</b-badge>
        </template> -->
      </b-table>
      <b-card-body v-if="data" class="d-flex justify-content-between flex-wrap pt-0">
        <b-pagination v-model="currentPage" :total-rows="data.total" :per-page="data.perPage" first-number last-number
          prev-class="prev-item" next-class="next-item" class="mb-0" @input="getNotify()">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCardTitle, BIcon, BDropdownItem, BDropdown, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,

} from 'bootstrap-vue'
import CreateModal from './create.vue'
import EditModal from './edit.vue'
import moment from 'moment-timezone'

export default {
  components: {
    BCardTitle,
    BIcon,
    BDropdownItem,
    BDropdown,
    BBadge,
    BOverlay,
    BIconController,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BButton,
    BCardBody,
    CreateModal,
    EditModal,
  },
  // props: ['items'],
  // emits: [],
  // filters: {
  //   convertDate(date) {
  //     return moment(date).tz('Asia/Bangkok').format('DD/MM/YYYY HH:ss')
  //   },
  // },
  data: () => ({
    isLoading: false,
    fields: [
      { key: 'title', label: 'เรื่อง' },
      // { key: 'message', label: 'ประเภท' },
      { key: 'type', label: 'ประเภท' },
      { key: 'priority', label: 'ความสำคัญ' },
      {
        key: 'created_at',
        label: 'ประกาศเมื่อ',
        formatter: value => (value
          ? moment(value)
            .tz('Asia/Bangkok')
            .format('DD-MM-YYYY HH:mm')
          : ''),
      },
      { key: 'actions', label: '#' },
    ],
    data: null,
    currentPage: 1,
    notifications: [],
  }),
  mounted() {
    this.getNotify()
  },
  methods: {
    async upsert(form) {
      this.isLoading = true

      const fd = new FormData;

      fd.append('id', form.id ? form.id : 'x');
      fd.append('title', form.title);
      fd.append('message', form.message);
      fd.append('type', form.type);
      fd.append('image', form.file);
      fd.append('priority', form.priority);

      try {
        const { data } = await this.$http.post('/notifications', form)

        this.getNotify()
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    // async submit(form) {
    //   this.isLoading = true;
    //   try {
    //     const { data } = await this.$http.post('/function/coupon/list', form);

    //     this.getNotify();
    //   } catch (e) {
    //     console.log(e);
    //   } finally {

    //     this.isLoading = false;
    //   }
    // },
    async getNotify(code) {
      // console.log(page, code)
      this.isLoading = true
      try {
        const { data } = await this.$http.get('/notifications', {
          params: {
            page: this.currentPage,
          },
        })

        this.notifications = data.data
        this.data = data
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
