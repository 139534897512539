<template>
  <div>
    <b-button v-b-modal.addCouponModal v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="gradient-success" block>
      สร้างประกาศ
    </b-button>

    <b-modal v-model="modal" size="lg" id="addCouponModal" title="เพิ่มคูปอง">

      <b-form>
        <div class="row">
          <div class="col-12">
            <b-form-group class="mr-1 flex-grow-1" label="ชื่อเรื่อง" label-for="title">
              <b-form-input v-model="form.title"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12">
            <b-form-group class="mr-1 flex-grow-1" label="รายละเอียด" label-for="message">
              <b-form-textarea v-model="form.message"></b-form-textarea>
            </b-form-group>
          </div>
          <div class="col-12">
            <b-form-group label="รูปภาพ" label-for="image_link">
              <b-form-file v-model="form.file" placeholder="เลือกไฟล์รูปภาพ..." accept="image/*"></b-form-file>
            </b-form-group>
          </div>
          <div class="col-3">
            <b-form-group label="ถึง" label-for="type">
              <b-form-select v-model="form.type" :options="typeOptions"></b-form-select>
            </b-form-group>
          </div>
          <div class="col-3">
            <b-form-group label="ระดับความสำคัญ" label-for="priority">
              <b-form-select v-model="form.priority" :options="priorityOptions"></b-form-select>
            </b-form-group>
          </div>
        </div>
      </b-form>

      <template #modal-footer>
        <b-button variant="success" @click="submit()">
          ยืนยัน
        </b-button>
        <b-button variant="danger" @click="cancel()">
          ยกเลิก
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormFile, BFormTextarea, BForm, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment-timezone';
import 'flatpickr/dist/flatpickr.css';
export default {
  components: {
    BFormFile, BFormTextarea, BForm, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
    flatPickr
  },
  directives: {
    Ripple
  },
  emits: ['submit'],
  data: () => ({
    modal: false,
    typeOptions: [
      { text: 'ทั้งหมด', value: 'ALL' },
      { text: 'สมาชิกที่ยังไม่ฝาก', value: 'NODEP' },
      { text: 'สมาชิกที่เข้าร่วมทัวร์นาเมนต์ปัจจุบัน', value: 'TOUR' },
    ],
    priorityOptions: [
      { text: 'สูง', value: 2 },
      { text: 'ปกติ', value: 1 },
    ],
    form: {
      id: null,
      title: '',
      message: '',
      type: 'ALL',
      priority: 1,
    }
  }),
  mounted() {
    // this.generateRandomString()
  },
  methods: {
    submit() {
      this.$emit('submit', this.form)
      this.modal = false
    },
    cancel() {
      this.modal = false;
    },
    generateRandomString() {
      const length = 10;
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      this.form.code = result;
    }
  }
}
</script>